import {
  REQUEST_DEMO,
  ROOT,
  PRICING,
  BRANDS,
  FAQ,
  CONTACT,
  CONCIERGE,
  WHY_US,
  DEVICE_SETUP_GUIDE,
  VIRTUAL_RESTAURANTS,
  V_DRIVE,
} from '../../helpers/constants';

export const dropdownMenuRoutes = [
  {
    path: ROOT,
    name: 'Home',
  },

  {
    path: REQUEST_DEMO,
    name: 'Request Demo',
  },
  {
    path: PRICING,
    name: 'Pricing',
  },
  {
    path: BRANDS,
    name: 'Our Brands',
  },
  {
    path: CONCIERGE,
    name: 'Concierge',
  },
  {
    path: V_DRIVE,
    name: 'vDrive',
  },
  {
    path: WHY_US,
    name: 'Why vGrubs?',
  },
  {
    path: VIRTUAL_RESTAURANTS,
    name: 'Virtual Restaurants',
  },
  {
    path: DEVICE_SETUP_GUIDE,
    name: 'Device Setup',
  },
  {
    path: FAQ,
    name: 'FAQ',
  },
  {
    path: CONTACT,
    name: 'Contact',
  },
];
