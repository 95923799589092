'use client';

import styled from 'styled-components';

export const TextButtonStyled = styled.button`
  height: 45px;
  /* padding: 0 30px; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.darkGray};
  background: none;
  transition: transform 100ms ease-in, color 0.2s ease;
  font-weight: bold;
  cursor: pointer;
  font-size: 15px;
  border: none;

  @media (min-width: 3500px) {
    font-size: 30px;
  }

  @media (max-width: 800px) {
    height: 40px;
    font-size: 14px;
  }

  @media (max-width: 380px) {
    height: 30px;
    font-size: 12px;
  }

  :hover {
    color: ${({ theme }) => theme.primary};
    svg {
      path {
        fill: ${({ theme }) => theme.primary};
      }
    }
  }

  svg {
    path {
      transition: fill 100ms ease-in, color 0.2s ease;
    }
  }

  :active {
    transform: translateY(3px);
  }

  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;
  max-width: ${(props) => props.maxWidth};

  @media (max-width: 800px) {
    margin: ${(props) => props.mSM};
    margin-top: ${(props) => props.mtSM}px;
    margin-bottom: ${(props) => props.mbSM}px;
    margin-left: ${(props) => props.mlSM}px;
    margin-right: ${(props) => props.mrSM}px;
    max-width: ${(props) => props.maxWidthSM};
  }
`;
