'use client';

import styled from 'styled-components';
import { motion } from 'framer-motion';

export const NavStyled = styled(motion.div)<{ $sticky: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: all 0.3s ease;
  padding: 0 100px;
  display: flex;
  justify-content: center;

  @media (max-width: 1000px) and (orientation: landscape) {
    padding: 0 30px;
  }

  @media (max-width: 800px) {
    padding: 0 30px;
  }

  ${({ $sticky }) =>
    $sticky &&
    `
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  background: #ffffff;

  `}

  > nav {
    max-width: 1440px;

    @media (min-width: 3000px) {
      max-width: 1740px;
    }

    @media (min-width: 3500px) {
      max-width: 80%;
    }

    width: 100vw;
  }
`;

export const NavigationStyled = styled.div<{ $sticky: boolean }>`
  gap: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 0;
  transition: all 0.3s ease;

  @media (max-width: 1450px) {
    padding: 20px 0;
  }

  img {
    transition: all 0.3s ease;
    width: 80px;

    @media (min-width: 3500px) {
      width: 160px;
    }

    @media (max-width: 1000px) and (orientation: landscape) {
      width: 60px;
    }

    @media (max-width: 800px) {
      width: 60px;
    }
    @media (max-width: 380px) {
      width: 40px;
    }
  }

  @media (max-width: 800px) {
    padding: 15px 0;
  }

  ${({ $sticky }) =>
    $sticky &&
    `
    padding:12px 0 8px 0;
      

  @media (min-width: 3500px) {
    padding: 30px 0 20px 0;
  
  }
    

      @media (max-width: 1450px) {
        padding:12px 0 8px 0;
      }

      img {
        width: 60px;

        @media (min-width: 3500px) {
      width: 120px;
    }

        @media (max-width: 800px) {
            width: 50px;
        }
          @media (max-width: 380px) {
            width: 30px;
          }
      }

 

 @media (max-width: 800px) {
  padding: 10px 0 5px 0;
  }


  `}
`;

export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid #e8e8e8;

  @media (max-width: 800px) {
    width: calc(100% - 300px);
  }
  @media (max-width: 400px) {
    display: none;
  }
`;

export const NavSeparator = styled.div<{ $sticky: boolean }>`
  width: 100%;
  height: 6px;
  background: ${({ theme, $sticky }) =>
    !$sticky ? theme.primary : theme.white};
  border-radius: 0px 0px 6px 6px;
  transition: background 0.2s ease;

  @media (min-width: 3500px) {
    height: 12px;
  }

  @media (max-width: 800px) {
    height: 5px;
  }
`;

export const ProgressBar = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: ${({ theme }) => theme.primary};
  transform-origin: 0%;

  @media (min-width: 3500px) {
    height: 12px;
  }

  @media (max-width: 800px) {
    height: 5px;
  }
`;

export const MenuIconStyled = styled(motion.div)`
  @media (min-width: 3500px) {
    svg {
      width: 48px;
      height: 48px;
    }
  }
`;
