'use client';

import { useScroll, useSpring } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { Button } from '../button/button';
import { DropdownMenu } from '../dropdownMenu/dropdownMenu';
import { TextButton } from '../textButton/textButton';
import { vGrubs } from '../../assets';
import {
  isAdmin,
  isPartner,
  isSales,
  t,
  useOnClickOutside,
} from '../../helpers/helpers';
import { ROOT, SCHEDULE_A_CALL } from '../../helpers/constants';

import { Div } from '../../styles';
import { Exit } from '../exit/exit';
import { Offer } from '../offer/offer';
import { MenuIcon } from './menuIcon';
import {
  NavigationStyled,
  NavSeparator,
  NavStyled,
  ProgressBar,
  Separator,
} from './navigation.styled';
import Link from 'next/link';
import Image from 'next/image';
import { usePathname } from 'next/navigation';

export const Navigation = () => {
  const [sticky, setSticky] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [exit, setExit] = useState(false);
  const [offer, setOffer] = useState(false);

  const pathname = usePathname();

  const dropdownRef = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 50 ? setSticky(true) : setSticky(false);
  };

  const handleMenu = () => setDropdown((prevState) => !prevState);

  useEffect(() => {
    if (dropdown) {
      handleMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useOnClickOutside(dropdownRef, () => dropdown && setDropdown(false));

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 200,
    damping: 30,
    restDelta: 0.001,
  });

  const app =
    !pathname.startsWith('/email-verify') && !isAdmin && !isPartner && !isSales;

  if (!app) return null;

  return (
    <NavStyled
      key="nav"
      $sticky={sticky}
      initial={{ opacity: 0, marginTop: -100 }}
      animate={{ opacity: 1, marginTop: 0 }}
      exit={{ opacity: 0, marginTop: -100 }}
      transition={{
        duration: 0.4,
        type: 'ease',
      }}
    >
      <nav>
        <ProgressBar style={{ scaleX }} />
        <NavSeparator $sticky={sticky} />
        <NavigationStyled $sticky={sticky}>
          <Link href={ROOT}>
            <Logo />
          </Link>

          <Separator />

          <Div $flex={t} $center={t} $gapLG="60px" $gap="30px" $gapSM="20px">
            <Div $desktop>
              <a
                href="https://manager.vgrubs.com"
                target="_blank"
                rel="noreferrer"
              >
                <TextButton negative={t}>Login</TextButton>
              </a>
            </Div>
            <Link href={SCHEDULE_A_CALL}>
              <Button $negative={t}>Schedule Call</Button>
            </Link>
            <Div $flex={t} $center={t} $relative={t} ref={dropdownRef}>
              <MenuIcon isOpen={dropdown} onClick={handleMenu} />
              <DropdownMenu
                isOpen={dropdown}
                setIsOpen={setDropdown}
                location={pathname}
              />
            </Div>
          </Div>
        </NavigationStyled>
      </nav>
      <Exit exit={exit} setExit={setExit} setOffer={setOffer} />
      <Offer offer={offer} setOffer={setOffer} setExit={setExit} />
    </NavStyled>
  );
};

const Logo = () => (
  <Image
    src={vGrubs}
    alt="vGrubs"
    priority
    sizes="100vw"
    style={{
      height: 'auto',
    }}
    width={500}
    height={300}
  />
);
