'use client';

import { AnimatePresence } from 'framer-motion';
import {
  DropdownStyled,
  NavLinkLoginStyled,
  NavLinkStyled,
} from './dropdownMenu.styled';
import { dropdownMenuRoutes } from './dropdownMenuData';
import { Dispatch, SetStateAction } from 'react';

const variants = {
  initial: {
    transform: 'scale(0)',
    transition: {
      type: 'spring',
      bounce: 0.2,
      duration: 0.8,
    },
  },
  animate: {
    transform: 'scale(1)',
  },

  exit: {
    transform: 'scale(0)',
    transition: {
      type: 'linear',
      duration: 0.3,
    },
  },
};

interface DropdownMenuProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  location: string;
}

export const DropdownMenu = ({
  isOpen,
  setIsOpen,
  location,
}: DropdownMenuProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <DropdownStyled
          key="DropdownMenu"
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{
            type: 'spring',
            bounce: 0.4,
            duration: 0.8,
          }}
        >
          <nav>
            {dropdownMenuRoutes.map(({ path, name }, i) => {
              return (
                <NavLinkStyled
                  key={i}
                  href={path}
                  onClick={() => location !== path && setIsOpen(false)}
                  selected={path === location}
                >
                  {name}
                </NavLinkStyled>
              );
            })}
            <a
              href="https://manager.vgrubs.com"
              target="_blank"
              rel="noreferrer"
            >
              <NavLinkLoginStyled>Login</NavLinkLoginStyled>
            </a>
          </nav>
        </DropdownStyled>
      )}
    </AnimatePresence>
  );
};
