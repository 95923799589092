import { ButtonSmallStyled, ButtonStyled } from './button.styled';

export const Button = ({ children, ...props }) => {
  return (
    <ButtonStyled type='button' {...props}>
      {children}
    </ButtonStyled>
  );
};

export const ButtonSmall = ({ children, ...props }) => {
  return (
    <ButtonSmallStyled type='button' {...props}>
      {children}
    </ButtonSmallStyled>
  );
};
