'use client';

import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Div } from '../../styles';
import { MenuIconStyled } from './navigation.styled';

const iconStyle = { display: 'flex', alignItems: 'center' };

interface MenuIconProps {
  isOpen: boolean;
  onClick: () => void;
}

export const MenuIcon = ({ isOpen, onClick }: MenuIconProps) => (
  <Div $pointer="true" onClick={() => onClick()}>
    <AnimatePresence mode="wait">
      {isOpen ? (
        <MenuIconStyled
          key="open"
          className="redSvgHover"
          initial={{ scale: 0.6, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.6, opacity: 0 }}
          transition={{
            duration: 0.2,
          }}
          style={iconStyle}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.354 4.29492C22.2134 3.44043 22.2134 2.05273 21.354 1.19824C20.4946 0.34375 19.099 0.34375 18.2396 1.19824L11.0003 8.40332L3.75402 1.20508C2.89465 0.350586 1.49902 0.350586 0.639648 1.20508C-0.219727 2.05957 -0.219727 3.44727 0.639648 4.30176L7.8859 11.5L0.646523 18.7051C-0.212852 19.5596 -0.212852 20.9473 0.646523 21.8018C1.5059 22.6563 2.90152 22.6563 3.7609 21.8018L11.0003 14.5967L18.2465 21.7949C19.1059 22.6494 20.5015 22.6494 21.3609 21.7949C22.2203 20.9404 22.2203 19.5527 21.3609 18.6982L14.1146 11.5L21.354 4.29492Z"
              fill="#4C4C4C"
            />
          </svg>
        </MenuIconStyled>
      ) : (
        <MenuIconStyled
          key="notOpen"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{
            duration: 0.1,
          }}
          style={iconStyle}
        >
          <svg
            className="redSvgHover"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#4C4C4C"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 5.14275C0 4.19454 0.766071 3.42847 1.71429 3.42847H22.2857C23.2339 3.42847 24 4.19454 24 5.14275C24 6.09097 23.2339 6.85704 22.2857 6.85704H1.71429C0.766071 6.85704 0 6.09097 0 5.14275ZM0 13.7142C0 12.766 0.766071 11.9999 1.71429 11.9999H22.2857C23.2339 11.9999 24 12.766 24 13.7142C24 14.6624 23.2339 15.4285 22.2857 15.4285H1.71429C0.766071 15.4285 0 14.6624 0 13.7142ZM24 22.2856C24 23.2338 23.2339 23.9999 22.2857 23.9999H1.71429C0.766071 23.9999 0 23.2338 0 22.2856C0 21.3374 0.766071 20.5713 1.71429 20.5713H22.2857C23.2339 20.5713 24 21.3374 24 22.2856Z" />
          </svg>
        </MenuIconStyled>
      )}
    </AnimatePresence>
  </Div>
);
