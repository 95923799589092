'use client';

import Link from 'next/link';
import styled from 'styled-components';

export const FooterStyled = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.gray};
  border-radius: 50px 50px 0px 0px;
  padding: 70px 100px 0px 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 1000px) and (orientation: landscape) {
    padding: 60px 30px 0px 30px;
  }

  @media (max-width: 800px) {
    padding: 60px 30px 0px 30px;
  }
`;

export const FooterWrapper = styled.div`
  padding: 0 100px;
  max-width: 1640px;
  width: 100vw;
`;

export const FooterMain = styled.div`
  width: 100%;
  display: flex;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  margin-bottom: 40px;
  max-width: 1440px;

  @media (min-width: 3000px) {
    max-width: 1740px;
  }

  @media (min-width: 3500px) {
    max-width: 80%;
  }

  /* > nav {
    text-align: right;
  } */

  @media (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const FooterMainCopyright = styled(FooterMain)`
  margin-bottom: 30px;
  gap: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    gap: 0px;
  }

  @media (max-width: 550px) {
    gap: 0px;
    flex-direction: column-reverse;
  }
`;

export const FooterLinkNavLink = styled(Link)`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: ${({ theme }) => theme.darkGray};
  :hover {
    color: ${({ theme }) => theme.primary};
  }
  margin-bottom: 10px;

  @media (min-width: 3500px) {
    font-size: 32px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  &.active {
    ${({ noActive, theme }) => !noActive && `color: ${theme.primary}`}
  }

  transition: color 0.2s ease;
  @media (max-width: 800px) {
    font-size: 14px;
  }
`;

export const FooterCopyrightLink = styled(FooterLinkNavLink)`
  font-size: 14px;
  margin-bottom: 0;

  @media (min-width: 3500px) {
    font-size: 28px;
  }

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

export const FooterLinkNavLinkLogin = styled.a`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: ${({ theme }) => theme.darkGray};

  @media (min-width: 3500px) {
    font-size: 32px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  :hover {
    color: ${({ theme }) => theme.primary};
  }

  &.active {
    ${({ noActive, theme }) => !noActive && `color: ${theme.primary}`}
  }

  transition: color 0.2s ease;
  @media (max-width: 800px) {
    font-size: 14px;
  }
`;

export const FooterLink = styled.a`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: ${({ theme }) => theme.darkGray};

  @media (min-width: 3500px) {
    font-size: 32px;
    line-height: 50px;
  }

  :hover {
    color: ${({ theme }) => theme.primary};
  }

  transition: color 0.2s ease;
  @media (max-width: 800px) {
    font-size: 14px;
  }
`;

export const FooterLinkP = styled.p`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: ${({ theme }) => theme.darkGray};

  @media (min-width: 3500px) {
    font-size: 32px;
    line-height: 50px;
  }

  :hover {
    color: ${({ theme }) => theme.primary};
  }

  transition: color 0.2s ease;
  @media (max-width: 800px) {
    font-size: 14px;
  }
`;

export const CopyrightSeparator = styled.div`
  height: 1px;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(242, 242, 242, 0) 0%,
    rgba(221, 221, 221, 1) 50%,
    rgba(242, 242, 242, 0) 100%
  );
  margin-bottom: 30px;
`;

export const CopyrightText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: ${({ theme }) => theme.darkGray};
  margin-bottom: 0px;

  @media (min-width: 3500px) {
    font-size: 28px;
    line-height: 36px;
  }

  @media (max-width: 800px) {
    text-align: center;
    font-size: 12px;
  }
`;

export const FooterSeparator = styled.div`
  width: 100%;
  height: 6px;
  background: ${({ theme }) => theme.primary};
  border-radius: 6px 6px 0px 0px;
  max-width: 1440px;

  @media (min-width: 3000px) {
    max-width: 1740px;
  }

  @media (min-width: 3500px) {
    max-width: 80%;
    height: 12px;
  }

  @media (max-width: 800px) {
    height: 5px;
  }
`;

export const LogoWrapper = styled.div`
  position: absolute;
  left: 17.5%;
  top: 0;
  transform: translate(-50%, -50%);
  max-width: 40%;
  width: 200px;

  @media (min-width: 3500px) {
    width: 400px;
  }

  @media (max-width: 800px) {
    width: 125px;
  }
`;

export const LocationLine = styled.div`
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.primary};
  margin-top: 20px;
  @media (min-width: 3500px) {
    width: 2px;
    height: 152px;
    margin-top: 40px;
  }
`;
