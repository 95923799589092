import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ModalStyled = styled(motion.div)<{ $logo: boolean }>`
  padding: ${({ $logo }) => ($logo ? '70px 70px 35px 70px' : '35px 50px')};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.white};
  z-index: 100;
  border-radius: 30px;
  text-align: center;
  width: 500px;
  max-width: 90%;

  > svg {
    /* width: 130px; */
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 800px) {
    max-width: calc(100% - 60px);
    padding: ${({ $logo }) => ($logo ? '60px 30px 35px 30px' : '30px')};

    > svg {
      width: 100px;
    }
  }
`;

export const ModalOverlay = styled(motion.div)`
  background-color: ${({ theme }) => theme.darkGray}4D;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
`;
