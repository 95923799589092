import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';
import {
  AFF,
  FSG,
  FSG_2,
  FSG_ORDER_BOOST,
  FSG_ORDER_BOOST_THANK_YOU,
  FSG_REVENUE_RECOVERY,
  FSG_REVENUE_RECOVERY_THANK_YOU,
  FSG_SUPERCHARGED,
  FSG_SUPERCHARGED_THANK_YOU,
  PRIVACY_POLICY,
  SCHEDULE_A_CALL,
  TERMS_AND_CONDITIONS,
} from '../../helpers';
import { A, H3, H4, P } from '../../styles';
import { Button } from '../button/button';
import Modal from '../modal/modal';

export const Offer = ({ offer, setOffer, setExit }) => {
  const navigate = useRouter();
  const location = usePathname();

  const lsValue = 'gb';
  const shownOnce = '1';
  const buttonClicked = '2';

  const handleButton = () => {
    localStorage.setItem(lsValue, buttonClicked);
    setOffer(false);
    setTimeout(() => {
      navigate.push(SCHEDULE_A_CALL);
    }, 200);
  };

  useEffect(() => {
    const popupStage = localStorage.getItem(lsValue);
    const pathname = location;

    if (
      pathname === PRIVACY_POLICY ||
      pathname === TERMS_AND_CONDITIONS ||
      pathname === FSG ||
      pathname === FSG_2 ||
      pathname === FSG_ORDER_BOOST ||
      pathname === FSG_REVENUE_RECOVERY ||
      pathname === FSG_SUPERCHARGED ||
      pathname === FSG_ORDER_BOOST_THANK_YOU ||
      pathname === FSG_REVENUE_RECOVERY_THANK_YOU ||
      pathname === FSG_SUPERCHARGED_THANK_YOU ||
      pathname === SCHEDULE_A_CALL ||
      pathname === AFF
    )
      return;

    if (popupStage === buttonClicked) {
      return;
    } else if (popupStage === shownOnce) {
      const timer2 = setTimeout(() => {
        setExit(false);
        setOffer(true);
      }, 300000);
      return () => clearTimeout(timer2);
    } else {
      const timer = setTimeout(() => {
        setExit(false);
        setOffer(true);
        localStorage.setItem(lsValue, shownOnce);
      }, 10000);
      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  return (
    <Modal isOpen={offer} setIsOpen={setOffer}>
      <H4 $mt="20">pricing</H4>
      <H3>
        <span>Get Boosted for $0 Upfront! </span>
      </H3>
      <P>
        It costs you nothing upfront to get your delivery sales boosted with our{' '}
        <b>BOOSTED</b> plan. That means you{' '}
        <A href={''} onClick={handleButton}>
          {' '}
          pay $0 until we&apos;re bringing in sales!
        </A>
      </P>
      <Button onClick={handleButton}>GET STARTED</Button>
    </Modal>
  );
};
