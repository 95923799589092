'use client';

import {
  Facebook,
  Instagram,
  LinkedIn,
  LocationIcon,
  Logo,
  MailIcon,
  PhoneIcon,
} from '../../assets';
import { FRANCHISE_NOTICE, mainRoutes } from '../../helpers/constants';
import {
  facebookLink,
  instagramLink,
  linkedInLink,
  t,
  yearToday,
} from '../../helpers/helpers';
import { Div, Icon } from '../../styles';
import {
  CopyrightSeparator,
  CopyrightText,
  FooterCopyrightLink,
  FooterLink,
  FooterLinkNavLink,
  FooterLinkNavLinkLogin,
  FooterLinkP,
  FooterMain,
  FooterMainCopyright,
  FooterSeparator,
  FooterStyled,
  LocationLine,
  LogoWrapper,
} from './footer.styled';

export const Footer = () => {
  const links = [...mainRoutes];

  return (
    <FooterStyled key="footer">
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <FooterMain>
        <Div $flex $columnSM $width="55%" $widthSM="100%">
          <Div $flex $alignCenter $width="45%" $widthSM="100%">
            <Div $flex={t} $gap="10px" $gapLG="20px" $alignCenter>
              <a
                href={facebookLink}
                target="_blank"
                rel="noreferrer"
                className="redSvgHover"
              >
                <Icon>
                  <Facebook />
                </Icon>
              </a>
              <a
                href={instagramLink}
                target="_blank"
                rel="noreferrer"
                className="redSvgHover"
              >
                <Icon>
                  <Instagram />
                </Icon>
              </a>

              <a
                href={linkedInLink}
                target="_blank"
                rel="noreferrer"
                className="redSvgHover"
              >
                <Icon>
                  <LinkedIn />
                </Icon>
              </a>
            </Div>
          </Div>
          <Div $width="55%" $widthSM="100%">
            <div>
              <Div>
                <Div $flex $gap="15px" $gapSM="10px" $mtSM="24">
                  <Div $flex $column $alignCenter>
                    <Icon>
                      <LocationIcon />
                    </Icon>
                    <LocationLine />
                  </Div>
                  <Div $flex $column $gap="20px" $gapLG="40px" $mbLG="40">
                    <FooterLinkP style={{ margin: 0 }}>
                      99 S Almaden Blvd <br /> San Jose, CA 95113
                    </FooterLinkP>
                    <FooterLinkP style={{ margin: 0 }}>
                      99 Wall St - Suite 5868 <br /> New York, NY 10005
                    </FooterLinkP>
                  </Div>
                </Div>

                <br />
                <Div
                  $gap="10px"
                  $gapLG="20px"
                  $mb="10"
                  $mbLG="20"
                  $flex
                  $alignCenter
                >
                  <Icon>
                    <MailIcon />
                  </Icon>
                  <FooterLink href="mailto: info@vgrubs.com">
                    info@vgrubs.com
                  </FooterLink>
                </Div>

                <Div $gap="10px" $gapLG="20px" $mb="10" $flex $alignCenter>
                  <Icon>
                    <PhoneIcon />
                  </Icon>
                  <FooterLink href="tel:(855) 5 V GRUBS">
                    (855) 5 V GRUBS
                  </FooterLink>
                </Div>

                <Div $gap="10px" $gapLG="20px" $mb="10" $flex $alignCenter>
                  <Icon>
                    <PhoneIcon />
                  </Icon>
                  <FooterLink href="tel:(818) 600-7488">
                    (818) 600-7488
                  </FooterLink>
                </Div>

                <Div $gap="10px" $gapLG="20px" $mb="10" $flex $alignCenter>
                  <Icon>
                    <PhoneIcon />
                  </Icon>
                  <FooterLink href="tel:(515) 884-7827">
                    (515) 884-7827
                  </FooterLink>
                </Div>

                <br />
              </Div>
            </div>
          </Div>
        </Div>

        <Div $flex $width="45%" $widthSM="100%">
          <Div $width="50%">
            <nav>
              {links.map(({ path, name }, i) => {
                if (i > 7) return null;
                return (
                  <FooterLinkNavLink key={i} href={path}>
                    {name}
                  </FooterLinkNavLink>
                );
              })}
            </nav>
          </Div>
          <Div $width="50%">
            <nav>
              {links.map(({ path, name }, i) => {
                if (i < 8) return null;
                return (
                  <FooterLinkNavLink key={i} href={path}>
                    {name}
                  </FooterLinkNavLink>
                );
              })}
              <FooterLinkNavLinkLogin
                href="https://manager.vgrubs.com"
                target="_blank"
                rel="noreferrer"
              >
                Login
              </FooterLinkNavLinkLogin>
            </nav>
          </Div>
        </Div>
      </FooterMain>
      <CopyrightSeparator />
      <FooterMainCopyright>
        <CopyrightText>
          <Div $desktop>
            Copyright © {yearToday} vGrubs Inc. All Rights Reserved / D-U-N-S
            Number: 118703507
          </Div>
          <Div $mobile>
            Copyright © {yearToday} vGrubs Inc. All Rights Reserved <br />{' '}
            D-U-N-S Number: 118703507
          </Div>
        </CopyrightText>

        <Div $flex $gap="50px" $gapSM="30px">
          <FooterCopyrightLink href={FRANCHISE_NOTICE}>
            Franchise Notice
          </FooterCopyrightLink>
        </Div>
      </FooterMainCopyright>

      <FooterSeparator />
    </FooterStyled>
  );
};
