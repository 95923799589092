'use client';

import styled from 'styled-components';
import { motion } from 'framer-motion';
import Link from 'next/link';

export const DropdownStyled = styled(motion.div)`
  transform-origin: top right;
  width: 350px;
  padding: 20px 35px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  background: ${({ theme }) => theme.white};
  border: 1px solid #f0f0f0;
  position: absolute;
  right: 0;
  top: 60px;
  overflow: hidden;
  z-index: 10;

  @media (min-width: 3500px) {
    width: 700px;
    top: 120px;
    padding: 30px 50px;
    border: 2px solid #f0f0f0;
  }

  @media (max-width: 1450px) {
    padding: 15px 25px;
  }

  @media (max-width: 800px) {
    width: calc(100vw - 60px);
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    width: 350px;
  }

  max-height: calc(100vh - 100px);
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const NavLinkStyled = styled(Link)<{ selected: boolean }>`
  font-weight: 400;
  font-size: 18px;
  color: ${({ theme }) => theme.darkGray};
  margin: 0;
  display: block;
  line-height: 50px;
  border-bottom: 1px solid #e5e5e5;

  :hover {
    color: ${({ theme }) => theme.primary};
  }

  &.active {
    color: ${({ theme }) => theme.primary};
  }

  ${({ selected, theme }) => selected && `color: ${theme.primary} !important;`}

  transition: 0.3s ease;

  @media (min-width: 3500px) {
    font-size: 36px;
    line-height: 120px;
    border-bottom: 2px solid #e5e5e5;
  }

  @media (max-width: 1450px) {
    font-size: 16px;
    line-height: 40px;
  }
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 50px;
  }

  @media (max-height: 580px) {
    line-height: 40px;
  }
`;

export const NavLinkLoginStyled = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.primary};
  margin: 0;
  display: block;
  line-height: 60px;
  font-weight: 700;

  @media (min-width: 3500px) {
    font-size: 36px;
    line-height: 120px;
  }

  /* border-bottom: 1px solid #e5e5e5; */

  :hover {
    color: ${({ theme }) => theme.primary};
  }

  &.active {
    color: ${({ theme }) => theme.primary};
  }

  transition: 0.3s ease;

  @media (max-width: 1450px) {
    font-size: 16px;
    line-height: 40px;
  }
`;
