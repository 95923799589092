export const heroImage = '/images/heroImage.webp';
export const contactImage = '/images/contactImage.webp';
export const vGrubs = '/images/vGrubs.svg';
export const vGrubsWhite = '/images/vGrubsWhite.svg';
export const vDrive = '/images/vDrive.svg';
export const img404 = '/images/404.webp';
export const chefPhoto = '/images/chefPhoto.webp';
export const doordash = '/images/doordash.webp';
export const grubhub = '/images/grubhub.webp';
export const heroImage2 = '/images/heroImage2.webp';
export const partnerPhone = '/images/partnerPhone.webp';
export const phone1 = '/images/phone1.webp';
export const phone2 = '/images/phone2.webp';
export const requestImage = '/images/requestImage.webp';
export const section7 = '/images/section7.webp';
export const tablet1 = '/images/tablet1.webp';
export const tablet2 = '/images/tablet2.webp';
export const thankYouImage = '/images/thankYouImage.webp';
export const uber = '/images/uber.webp';
export const setupDevices = '/images/setupDevices.webp';
export const pricing1 = '/images/pricing1.webp';
export const pricing2 = '/images/pricing2.webp';
export const success = '/images/success.webp';
export const section3Logos = '/images/section3Logos.webp';
export const sales = '/images/sales.webp';
export const concierge1 = '/images/concierge1.webp';
export const concierge2 = '/images/concierge2.webp';
export const whyUs1 = '/images/whyUs1.webp';
export const whyUs2 = '/images/whyUs2.webp';
export const whyUs3 = '/images/whyUs3.webp';
export const fsgLogo = '/images/FsgLogo.webp';
export const bagel = '/images/bagel.webp';
export const bagel2 = '/images/bagel2.webp';
export const thankYouFsg = '/images/thankYouFsg.webp';
export const islandBagel = '/images/islandBagel.webp';
export const bagelBoss = '/images/bagelBoss.webp';
export const whyUs1Fsg = '/images/whyUs1Fsg.webp';
export const virtualRestaurants1 = '/images/virtualRestaurants1.webp';
export const virtualRestaurants2 = '/images/virtualRestaurants2.webp';
export const virtualRestaurants3 = '/images/virtualRestaurants3.webp';
export const virtualRestaurants4 = '/images/virtualRestaurants4.webp';
export const virtualRestaurants5 = '/images/virtualRestaurants5.webp';
export const money = '/images/money.webp';
export const payment = '/images/payment.webp';
export const vDrive1 = '/images/vDrive1.png';
export const vDrive2 = '/images/vDrive2.png';
export const vDrive3 = '/images/vDrive3.png';

export * from './svg';
export * from './adminSvg';

export const chickenBar = '/images/brands/logo/chickenBar.jpg';
export const bagNWitch = '/images/brands/logo/bagNWitch.jpg';
export const smoothieCentral = '/images/brands/logo/smoothieCentral.jpg';
export const mikushi = '/images/brands/logo/mikushi.jpg';
export const nycBagles = '/images/brands/logo/nycBagles.jpg';
export const pizzatalia = '/images/brands/logo/pizzatalia.jpg';
export const saladAce = '/images/brands/logo/saladAce.jpg';
export const theBurger = '/images/brands/logo/theBurger.jpg';
export const buka = '/images/brands/logo/buka.jpg';
export const soupKettle = '/images/brands/logo/soupKettle.jpg';
export const burgerz = '/images/brands/logo/burgerz.jpg';
export const fishka = '/images/brands/logo/fishka.jpg';
export const gyroKing = '/images/brands/logo/gyroKing.jpg';
export const wingz = '/images/brands/logo/wingz.jpg';
export const heroMania = '/images/brands/logo/heroMania.jpg';
export const electicSoups = '/images/brands/logo/electicSoups.jpg';
export const chickNFries = '/images/brands/logo/chickNFries.jpg';
export const JuicyJuice = '/images/brands/logo/JuicyJuice.jpg';
export const wrap = '/images/brands/logo/wrap.jpg';
export const coffieZen = '/images/brands/logo/coffieZen.jpg';
export const omeletteStation = '/images/brands/logo/omeletteStation.jpg';
export const phillyCheez = '/images/brands/logo/phillyCheez.jpg';
export const paniniPoint = '/images/brands/logo/paniniPoint.jpg';
export const pizzaBagels = '/images/brands/logo/pizzaBagels.jpg';

export const bagels = '/images/brands/logo/bagels.jpg';
export const burritoBandito = '/images/brands/logo/burritoBandito.jpg';
export const coldCutz = '/images/brands/logo/coldCutz.jpg';
export const crazyTako = '/images/brands/logo/crazyTaco.jpg';
export const crunchyBites = '/images/brands/logo/crunchyBites.jpg';
export const fishNChips = '/images/brands/logo/fishNChips.jpg';
export const fishTime = '/images/brands/logo/fishTime.jpg';
export const greekPalace = '/images/brands/logo/greekPalace.jpg';
export const grillBar = '/images/brands/logo/grillBar.jpg';
export const grillTime = '/images/brands/logo/grillTime.jpg';
export const GrillStar = '/images/brands/logo/GrillStar.jpg';
export const italianos = '/images/brands/logo/italianos.jpg';
export const meatMe = '/images/brands/logo/meatMe.jpg';
export const meatz = '/images/brands/logo/meatz.jpg';
export const nycDinner = '/images/brands/logo/nycDinner.jpg';
export const pitaz = '/images/brands/logo/pitaz.jpg';
export const pizzaRoma = '/images/brands/logo/pizzaRoma.jpg';
export const platter = '/images/brands/logo/platter.jpg';
export const riseBowls = '/images/brands/logo/riseBowls.jpg';
export const skewers = '/images/brands/logo/skewers.jpg';
export const steaksNChops = '/images/brands/logo/steaksNChops.jpg';
export const tastyPanini = '/images/brands/logo/tastyPanini.jpg';
export const wrapz = '/images/brands/logo/wrapz.jpg';

export const americanBistro = '/images/brands/logo/americanBistro.jpg';
export const eatMyPasta = '/images/brands/logo/eatMyPasta.jpg';
export const hotDawgs = '/images/brands/logo/hotDawgs.jpg';
export const iceNCream = '/images/brands/logo/iceNCream.jpg';
export const juicyDogs = '/images/brands/logo/juicyDogs.jpg';
export const mexicanGrill = '/images/brands/logo/mexicanGrill.jpg';
export const pancakePlanet = '/images/brands/logo/pancakePlanet.jpg';
export const pastaLovers = '/images/brands/logo/pastaLovers.jpg';
export const pizza4Me = '/images/brands/logo/pizza4Me.jpg';
export const pizza888 = '/images/brands/logo/pizza888.jpg';
export const quesadillaFiesta = '/images/brands/logo/quesadillaFiesta.jpg';
export const saladSnack = '/images/brands/logo/saladSnack.jpg';
export const sandwichSpread = '/images/brands/logo/sandwichSpread.jpg';
export const shakeSpot = '/images/brands/logo/shakeSpot.jpg';
export const sultanKebab = '/images/brands/logo/sultanKebab.jpg';
export const veganOasisCafe = '/images/brands/logo/veganOasisCafe.jpg';

export const bagelsB = '/images/brands/background/bagelsB.jpg';
export const burritoBanditoB = '/images/brands/background/burritoBanditoB.jpg';
export const coldCutzB = '/images/brands/background/coldCutzB.jpg';
export const crazyTakoB = '/images/brands/background/crazyTakoB.jpg';
export const crunchyBitesB = '/images/brands/background/crunchyBitesB.jpg';
export const fishNChipsB = '/images/brands/background/fishNChipsB.jpg';
export const fishTimeB = '/images/brands/background/fishTimeB.jpg';
export const greekPalaceB = '/images/brands/background/greekPalaceB.jpg';
export const grillBarB = '/images/brands/background/grillBarB.jpg';
export const grillTimeB = '/images/brands/background/grillTimeB.jpg';
export const GrillStarB = '/images/brands/background/GrillStarB.jpg';
export const italianosB = '/images/brands/background/italianosB.jpg';
export const meatMeB = '/images/brands/background/meatMeB.jpg';
export const meatzB = '/images/brands/background/meatzB.jpg';
export const nycDinnerB = '/images/brands/background/nycDinnerB.jpg';
export const pitazB = '/images/brands/background/pitazB.jpg';
export const pizzaRomaB = '/images/brands/background/pizzaRomaB.jpg';
export const platterB = '/images/brands/background/platterB.jpg';
export const riseBowlsB = '/images/brands/background/riseBowlsB.jpg';
export const skewersB = '/images/brands/background/skewersB.jpg';
export const steaksNChopsB = '/images/brands/background/steaksNChopsB.jpg';
export const tastyPaniniB = '/images/brands/background/tastyPaniniB.jpg';
export const wrapzB = '/images/brands/background/wrapzB.jpg';

export const bagNWitchB = '/images/brands/background/bagNWitchB.jpg';
export const bukaB = '/images/brands/background/bukaB.jpg';
export const burgerzB = '/images/brands/background/burgerzB.jpg';
export const chickenBarB = '/images/brands/background/chickenBarB.jpg';
export const chickNFriesB = '/images/brands/background/chickNFriesB.jpg';
export const coffieZenB = '/images/brands/background/coffieZenB.jpg';
export const electicSoupsB = '/images/brands/background/electicSoupsB.jpg';
export const fishkaB = '/images/brands/background/fishkaB.jpg';
export const gyroKingB = '/images/brands/background/gyroKingB.jpg';
export const heroManiaB = '/images/brands/background/heroManiaB.jpg';
export const JuicyJuiceB = '/images/brands/background/JuicyJuiceB.jpg';
export const mikushiB = '/images/brands/background/mikushiB.jpg';
export const nycBagelsB = '/images/brands/background/nycBagelsB.jpg';
export const omeletteStationB =
  '/images/brands/background/omeletteStationB.jpg';
export const paniniPointB = '/images/brands/background/paniniPointB.jpg';
export const phillyCheezB = '/images/brands/background/phillyCheezB.jpg';
export const pizzaBagelsB = '/images/brands/background/pizzaBagelsB.jpg';
export const pizzataliaB = '/images/brands/background/pizzataliaB.jpg';
export const saladAceB = '/images/brands/background/saladAceB.jpg';
export const smoothieCentralB =
  '/images/brands/background/smoothieCentralB.jpg';
export const soupKettleB = '/images/brands/background/soupKettleB.jpg';
export const theBurgerB = '/images/brands/background/theBurgerB.jpg';
export const wingzB = '/images/brands/background/wingzB.jpg';
export const wrapB = '/images/brands/background/wrapB.jpg';

export const americanBistroB = '/images/brands/background/americanBistroB.jpg';
export const eatMyPastaB = '/images/brands/background/eatMyPastaB.jpg';
export const hotDawgsB = '/images/brands/background/hotDawgsB.jpg';
export const iceNCreamB = '/images/brands/background/iceNCreamB.jpg';
export const juicyDogsB = '/images/brands/background/juicyDogsB.jpg';
export const mexicanGrillB = '/images/brands/background/mexicanGrillB.jpg';
export const pancakePlanetB = '/images/brands/background/pancakePlanetB.jpg';
export const pastaLoversB = '/images/brands/background/pastaLoversB.jpg';
export const pizza4MeB = '/images/brands/background/pizza4MeB.jpg';
export const pizza888B = '/images/brands/background/pizza888B.jpg';
export const quesadillaFiestaB =
  '/images/brands/background/quesadillaFiestaB.jpg';
export const saladSnackB = '/images/brands/background/saladSnackB.jpg';
export const sandwichSpreadB = '/images/brands/background/sandwichSpreadB.jpg';
export const shakeSpotB = '/images/brands/background/shakeSpotB.jpg';
export const sultanKebabB = '/images/brands/background/sultanKebabB.jpg';
export const veganOasisCafeB = '/images/brands/background/veganOasisCafeB.jpg';
