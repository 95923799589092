import ExitIntent from 'exit-intent';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import {
  AFF,
  FSG,
  FSG_2,
  FSG_ORDER_BOOST,
  FSG_ORDER_BOOST_THANK_YOU,
  FSG_REVENUE_RECOVERY,
  FSG_REVENUE_RECOVERY_THANK_YOU,
  FSG_SUPERCHARGED,
  FSG_SUPERCHARGED_THANK_YOU,
  PRIVACY_POLICY,
  SCHEDULE_A_CALL,
  TERMS_AND_CONDITIONS,
} from '../../helpers';
import { H3, H4, P } from '../../styles';
import { Button } from '../button/button';
import Modal from '../modal/modal';

export const Exit = ({ exit, setExit, setOffer }) => {
  // const navigate = useRouter();
  const location = usePathname();

  const handleButton = () => {
    setExit(false);
    setTimeout(() => {
      // navigate.push(SCHEDULE_A_CALL);
    }, 300);
  };

  useEffect(() => {
    const clicked = localStorage.getItem('ex');
    const pathname = location.pathname;

    const timer = setTimeout(() => {
      if (
        pathname === PRIVACY_POLICY ||
        pathname === TERMS_AND_CONDITIONS ||
        pathname === FSG ||
        pathname === FSG_2 ||
        pathname === FSG_ORDER_BOOST ||
        pathname === FSG_REVENUE_RECOVERY ||
        pathname === FSG_SUPERCHARGED ||
        pathname === FSG_ORDER_BOOST_THANK_YOU ||
        pathname === FSG_REVENUE_RECOVERY_THANK_YOU ||
        pathname === FSG_SUPERCHARGED_THANK_YOU ||
        pathname === SCHEDULE_A_CALL ||
        pathname === AFF
      )
        return;

      if (clicked === '1') return;
      ExitIntent({
        threshold: 10,
        maxDisplays: 1,
        eventThrottle: 100,
        onExitIntent: () => {
          setOffer(false);
          setExit(true);
        },
      });
      localStorage.setItem('ex', '1');
    }, 120000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={exit} setIsOpen={setExit}>
      <H4>Wait don&apos;t go!</H4>
      <H3>Word of mouth is getting a little out of hand.</H3>
      <P>
        Request a demo, to get a
        <span>
          <b> free tablet </b>
        </span>
        that gets you more sales!
      </P>

      <Button onClick={handleButton}>Request Demo</Button>
    </Modal>
  );
};
